@font-face {
  font-family: "Raleway-Italic";
  src: url("assets/fonts/Railway/Raleway-Italic-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Raleway-Variable";
  src: url("assets/fonts/Railway/Raleway-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Raleway-Bold-Italic";
  src: url("assets/fonts/Railway/Raleway-BoldItalic.ttf");
}
@font-face {
  font-family: "Raleway-Extra-Bold-Italic";
  src: url("assets/fonts/Railway/Raleway-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: "Raleway-Semi-Bold-Italic";
  src: url("assets/fonts/Railway/Raleway-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Raleway-Semi-Bold";
  src: url("assets/fonts/Railway/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("assets/fonts/Railway/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("assets/fonts/Railway/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway-Extra-Bold";
  src: url("assets/fonts/Railway/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("assets/fonts/Railway/Raleway-Bold.ttf");
}

.body-for-terms{
  padding-left: 120px;
  padding-right: 120px;
}
@media screen and (max-width: 780px) {
  .body-for-terms {
      padding-left: 0px;
      padding-right: 0px;
  }
}
.main_heading-for-terms {
  font-size: 42px;
  font-family: "Raleway-Variable";
  font-weight: 800;
  text-align: center;
}
.content_heading-for-terms {
  font-size: 32px;
  text-align: left;
  font-family: "Raleway-Variable";
  font-weight: 700;
  margin-block-end: 0px !important;
}
.content_sub_heading-for-terms {
  font-size: 24px;
  font-family: "Raleway-Variable";
  font-weight: 600;
  margin-block-end: 0px !important;
  margin-top: 20px;
}
.content-for-terms {
  font-size: 18px;
  font-family: "Raleway-Variable";
  text-align: left;
  /* margin-left: 20px; */
  line-height: 30px;
  margin-top: 30px;
}

.elipsis-title {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 1; /* Set the number of lines */
  -webkit-box-orient: vertical;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.elipsis-title-blogs {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* Set the number of lines */
  -webkit-box-orient: vertical;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery {
  display: flex;
  /* gap: 16px; */
}

.gallery-item {
  flex: 1;
  transition: opacity 0.3s;
  cursor: pointer;
}

/* .ql-editor {
  padding: 0px !important;
}
.ql-editor p,
h1,
h2,
h3,
h3,
h4,
h5,
h6,
ul {
  padding: 0px 40px !important;
}
.custom-quill-instancee .ql-editor p:has(img) {
  padding: 0 !important;
}
.ql-editor ul li::before {
  color: #ee6931 !important;
  list-style: "none" !important;
  content: "\25CF" !important;
}
*/
.gallery-item img {
  width: 100%;
  display: block;
  opacity: 0.7; /* Default opacity for a dull effect */
  transition: opacity 0.3s;
}

.custom-quill .ql-toolbar {
  position: sticky;
  top: 0; /* Adjust the offset as needed */
  z-index: 100; /* Ensure it stays above other elements */
  background-color: #fff; /* Optional: match the editor's background */
  border-radius: 16px 16px 0 0; /* Match border radius */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for separation */
}

.gallery-item:hover img {
  opacity: 1; /* Fully visible on hover */
}



/* Ensure all sibling items remain dull when hovering over one */
.gallery-item:hover ~ .gallery-item img {
  opacity: 0.7;
}

.elipsis-title-desc {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 3; /* Set the number of lines */
  -webkit-box-orient: vertical;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-quill-instancee .ql-editor ul li:not(:last-child) {
  padding-bottom: 10px; /* Apply padding to all except the last */
}
.custom-quill-instancee .ql-editor a {
  color: white !important;
  background: #ee6931;
  text-decoration: none;
  /* font-weight: bold; */
  /* border: 2px solid red; */
  border-radius: 35px;
  padding: 18px 35px;
  font-family: "Raleway-Bold";
  cursor: pointer !important;
}

.custom-quill-instancee .ql-editor {
  padding: 0px !important;
}
.custom-quill-instancee .ql-editor p,
.custom-quill-instancee .ql-editor h1,
.custom-quill-instancee .ql-editor h2,
.custom-quill-instancee .ql-editor h3,
.custom-quill-instancee .ql-editor h4,
.custom-quill-instancee .ql-editor h5,
.custom-quill-instancee .ql-editor h6,
.custom-quill-instancee .ql-editor ul {
  padding: 0px 40px !important;
}
/* Remove padding from <p> tags that contain <img> */
.custom-quill-instancee .ql-editor p:has(img) {
  padding: 0 !important;
}


.custom-quill-instancee .ql-editor ul li::before {
  color: #ee6931 !important;
  list-style: "none" !important;
   content: "\25CF" !important; 
} 


.custom-quill-instancee .ql-snow a {
  /* color:#3e3e3e !important; */
  cursor: pointer !important;
}
.custom-quill-instancee .ql-snow .ql-editor a {
  text-decoration: none !important;
}
.custom-quill-instancee .ql-snow a {
  /* color:#3e3e3e !important; */
  cursor: pointer !important;
}
.custom-quill-instancee .ql-snow .ql-editor a {
  text-decoration: none !important;
}
.custom-quill-instancee .ql-snow .ql-editor p img {
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}
.custom-quill-instancee .ql-editor p:first-of-type img {
  border-radius: 12px 12px 0px 0px !important;
  padding: 2px 2px 0px 2px !important;
}

.checkedIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ee6931;
  background-color: #ee6931;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Modern browsers */
}
.uncheckedIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ee6931;
  background-color: transparent;
}


/* body{
  background-color: #F9C9B3;
} */
/* .notranslate {
  display: none !important;
} */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.web-screen {
  display: none;
}
.mobile-screen {
  display: block;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}

.scroll-container {
  display: flex !important;
  justify-self: center;
  align-items: center;
}

.actionSheetContent {
  max-height: 90vh; /* Set a maximum height, adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}
.custom-strength-bar .react-password-strength-bar-message {
  font-size: 16px !important; /* Adjust the font size */
  font-family: "Raleway-Variable" !important; /* Change the font family */
  color: #333 !important; /* Optional: Change the text color */
}
.actionSheetFooter {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  padding: 16px;
  height: 20vh;
  border-top: 1px solid #e0e0e0; /* Add a border to separate the footer */
  background-color: #fff; /* Ensure a white background */
}

body {
  overscroll-behavior: contain;
}
@media screen and (min-width: 700px) {
  .web-screen {
    display: flex;
    flex-direction: column;
  }
  .mobile-screen {
    display: none;
  }
}
@media screen and (min-width: 780px) {
  .web-screen {
    display: flex;
    flex-direction: column;
  }
  .mobile-screen {
    display: none;
  }
}

.pac-icon {
  background-image: url("../src/assets/images/FrankIcons/pinIconForAutocomplete.png"); /* Adjust this path */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px;
  z-index: 11111;
}

.cat-text {
  font-family: "Raleway-Variable" !important;
}

.Raleway-Italic {
  font-family: "Raleway-Italic";
}
.Raleway-Bold-Italic {
  font-family: "Raleway-Bold-Italic";
}

.Raleway-Variable {
  font-family: "Raleway-Variable";
}
.Raleway-Semi-Bold-Italic {
  font-family: "Raleway-Semi-Bold-Italic";
}
.Raleway-Medium {
  font-family: "Raleway-Medium";
}
.Raleway-Semi-Bold {
  font-family: "Raleway-Semi-Bold";
}
.Raleway-Extra-Bold {
  font-family: "Raleway-Extra-Bold";
}
.Raleway-Bold {
  font-family: "Raleway-Bold";
}
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  background-color: #fff;
}
.heading-one {
  font-size: 48px;
  font-weight: 800;
  font-family: "Raleway-Variable";
}
.heading-two {
  font-size: 38px;
  font-weight: 800;
  font-family: "Raleway-Variable";
}
.paragraph-text {
  font-size: 18px;
  font-family: "Raleway-Variable";
  font-size: 500;
}
.paragraph-text-two {
  font-size: 22px;
  font-family: "Raleway-italic";
  line-height: 30px;
  color: #000;
}
.triangle {
  background-image: linear-gradient(to bottom right, #ee6931 50%, #3026f1 50%);
  height: 200px;
  border-radius: 16px;
}

.triangle-two {
  background-image: linear-gradient(to bottom right, #fad9b6 65%, #fff 35%);
  border-radius: 16px;
  font-family: "Raleway-Italic";
  width: 1000px !important;
  position: absolute;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 50px;
}

.custom-button {
  padding: 10px 20px; /* Adjust padding as needed */
  border: 2px solid #3026f1; /* Border color to match the text */
  border-radius: 10px; /* Rounded corners */
  background-color: white; /* Button background color */
  color: #3026f1; /* Text color */
  font-weight: bold; /* Bold text */
  font-size: 16px; /* Adjust text size as needed */
  text-transform: uppercase; /* Uppercase text */
  position: relative; /* Position for shadow */
  outline: none; /* Remove outline on focus */
  cursor: pointer; /* Pointer on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-weight: 900 !important;
}

/* Shadow Effect */
.custom-button::after {
  content: "";
  position: absolute;
  bottom: -30px; /* Position the shadow */
  left: 50%; /* Center it horizontally */
  width: 110%; /* Shadow width */
  height: 15px; /* Shadow height */
  background-color: rgba(0, 0, 0, 0.1); /* Shadow color */
  border-radius: 50%; /* Rounded shadow */
  transform: translateX(-50%); /* Center alignment */
  z-index: -1; /* Place it behind the button */
}

.custom-button:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.crange-image {
  position: absolute;
  left: 5%;
  height: 450px;
  width: 450px;
  top: -23%;
  z-index: 1111;
}
@media screen and (max-width: 1120px) {
  .triangle-two {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 1020px) {
  .triangle-two {
    width: 950px !important;
    margin-bottom: 160px;
  }
  .crange-image {
    position: absolute;
    left: 5%;
    height: 450px;
    width: 450px;
    top: -13%;
    z-index: 1111;
  }
}

@media screen and (max-width: 930px) {
  .triangle-two {
    width: 850px !important;
    margin-bottom: 190px;
  }
  .crange-image {
    position: absolute;
    left: 5%;
    height: 400px;
    width: 400px;
    top: -1%;
    z-index: 1111;
  }
}

@media screen and (max-width: 700px) {
  .triangle-two {
    display: none;
  }
  .crange-image {
    display: none;
  }
}
.contained_button {
  background-color: #ee6931;
  font-family: "Raleway-Bold-Italic";
  font-size: 32px;
}
.custom-tab {
  text-transform: capitalize;
  font-family: "Raleway-Variable";
  font-weight: 700;
  font-size: 16px;
}
.profile-headings {
  font-size: 16px;
  font-family: "Raleway-Extra-Bold";
  color: #000;
}
.profile-text {
  font-size: 15px;
  font-family: "Raleway-Variable";
  font-weight: 500;
  color: #000;
}
.orange-background {
  background-image: url("../src/assets/images/FrankIcons/orange-background.png")
    no-repeat center;
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.title-text-announces {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.annouces-main-div::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.annouces-main-div::-webkit-scrollbar-track {
  background: #f9c7b8; /* color of the tracking area */
}

.annouces-main-div::-webkit-scrollbar-thumb {
  background-color: blue; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #f9c7b8; /* creates padding around scroll thumb */
}

.contained_button {
  background-color: #3026f1 !important;
  color: #fff !important;
  border-radius: 32px !important;
  font-family: "Raleway-Italic" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  white-space: "nowrap";
  text-align: center;
}
.contained_button_two {
  background-color: #3026f1 !important;
  color: #fff !important;
  border-radius: 32px !important;
  border: 1px solid #3026f1 !important;
  font-family: "Raleway-Extra-Bold" !important;
  font-size: 17px !important;
  white-space: "nowrap";
  text-align: center;
}

.outlined_button_two {
  background-color: transparent !important;
  color: #3026f1 !important;
  border: 1px solid #3026f1 !important;
  border-radius: 32px !important;
  font-family: "Raleway-Extra-Bold" !important;
  font-size: 17px !important;
  white-space: "nowrap";
  text-align: center;
}

.outlined_button {
  background-color: transparent !important;
  color: #3026f1 !important;
  border: 1px solid #3026f1 !important;
  border-radius: 32px !important;
  font-family: "Raleway-Bold-Italic" !important;
  font-size: 18px !important;
  /* font-weight: 700 !important; */
  white-space: "nowrap";
  text-align: center;
}
.outlined_button_disable {
  background-color: #f0f0f0 !important;
  color: grey !important;
  border: 1px solid grey !important;
  border-radius: 32px !important;
  font-family: "Raleway-Italic" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  white-space: "nowrap";
  text-align: center;
}
.outlined_button_Two_disable {
  background-color: transparent !important;
  color: grey !important;
  border: 1px solid grey !important;
  border-radius: 32px !important;
  font-family: "Raleway-Italic" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  white-space: "nowrap";
  text-align: center;
}

a {
  color: #3026f1;
  text-decoration: underline;
}

.outlined_button_two {
  background-color: transparent !important;
  color: #3026f1 !important;
  border: 1px solid #3026f1 !important;
  border-radius: 32px !important;
  font-family: "Raleway-Extra-Bold" !important;
  font-size: 18px !important;
  white-space: "nowrap";
  text-align: center;
}

.white_outlined_button {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 32px !important;
  font-family: "Raleway-Extra-Bold" !important;
  font-size: 18px !important;
  white-space: "nowrap";
  text-align: center;
}

.button_width {
  width: 170px;
}

.mobile_contained_button {
  background-color: #3026f1 !important;
  color: #fff !important;
  border-radius: 32px !important;
  font-family: "Raleway-Italic" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: "nowrap";
  text-align: center;
}
.mobile_contained_button:hover {
  background-color: transparent !important;
  color: #3026f1 !important;
  border-radius: 32px !important;
  border: 1px solid #3026f1 !important;
  font-family: "Raleway-Italic" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: "nowrap";
  text-align: center;
}

.mobile_outlined_button {
  background-color: transparent !important;
  color: #3026f1 !important;
  border: 1px solid #3026f1 !important;
  border-radius: 32px !important;
  font-family: "Raleway-Italic" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: nowrap !important;
  text-align: center;
}

.mobile_button_width {
  width: 170px;
}
.speech-bubble {
  position: relative;
  background: white;
  border: 2px solid blue;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
}

.speech-bubble::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white; /* White to match the background color */
  border-top-color: white; /* Ensure the top part is white */
  z-index: 1;
}

.speech-bubble::before {
  content: "";
  position: absolute;
  bottom: -14px; /* Adjust to create the blue border around the arrow */
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid blue; /* Same as the border color of the box */
  z-index: 0;
}
.css-1puturl-MuiDivider-root::before,
.css-1puturl-MuiDivider-root::after {
  width: 100%;
  border-top: thin solid rgb(255 255 255) !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Raleway-Italic" !important;
}

.card-container {
  display: flex;
  align-items: center;
  background-color: #f4f3f6;
  border-radius: 16px;
  padding: 8px;
  font-family: "Raleway-Variable", sans-serif;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}
.card-image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: contain;
}
.card-details {
  flex: 1;
}
.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 30%;
  background-color: #333;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11111111111;
  /* height: 200px; */
  padding: 20px;
}

.cookie-banner p {
  margin: 0;
  flex-grow: 1;
}

.cookie-banner button {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  background-color: #ee6931;
  border-radius: 12px;
  color: white;
  display: flex;
  float: left;
  font-family: "Raleway-Variable";
  cursor: pointer;
}

.cookie-banner button:hover {
  background-color: #cc5820;
}
.card-title {
  font-size: 14px;
  color: #1a1a1a;
  display: -webkit-box; /* Use for multi-line ellipsis */
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  text-overflow: ellipsis; /* Add ellipsis */
  transition: max-height 0.3s ease; /* Smooth transition */
  max-height: 3em; /* Height for 2 lines (adjust based on font size) */
  font-family: "Raleway-Bold";
}

.card-dimensions {
  font-size: 14px;
  color: #8c8c8c;
}

.card-quantity {
  font-size: 18px;
  color: #1a1a1a;
  font-family: "Raleway-Bold";
}

.card-size {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
}

.card-images-count {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #8c8c8c;
  margin-top: 8px;
}

.card-images-icon {
  margin-right: 4px;
}

.delete-icon {
  cursor: pointer;
  margin-left: 16px;
}

.page-title-one {
  font-size: 40px;
  line-height: 48px;
  font-size: 2.5rem;
  letter-spacing: 0% (0em);
  font-family: "Raleway-Extra-Bold";
}

.page-title-two {
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  letter-spacing: 0% (0em);
  font-family: "Raleway-Variable";
}
.pac-container {
  z-index: 11111111 !important;
  font-family: "Raleway-Italic" !important;
  padding: 4px !important;
  border-radius: 12px !important;
  width: 100%;
}

.css-ka7ti6-MuiAutocomplete-root.Mui-focused .MuiAutocomplete-clearIndicator {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.container-for-offer-card {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  background-color: #f54600;
  color: white;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-for-offer-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-for-offer-card {
  margin-left: 20px;
}

.text-for-offer-card h2 {
  font-size: 1.8rem;
  margin: 0;
}

.text-for-offer-card p {
  font-size: 1.2rem;
  margin: 0;
}

.amount-for-offer-card {
  font-size: 26px;
  font-weight: bold;
  margin-left: 20px;
}

.shopping-image-for-offer-card {
  width: 100px;
  height: auto;
}

.background-shape-for-offer-card {
  position: absolute;
  top: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 0;
}

.background-shape-for-offer-card::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: 100px;
  left: 150px;
}

@media screen and (max-width: 768px) {
  .content-for-offer-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .amount-for-offer-card {
    margin-top: 10px;
    font-size: 1rem;
  }

  .text-for-offer-card h2 {
    font-size: 1.4rem;
  }

  .text-for-offer-card p {
    font-size: 1rem;
  }

  .shopping-image-for-offer-card {
    width: 80px;
  }
}

/* Remove spinner buttons for number input fields in Firefox */
input[type="number"] {
  appearance: textfield; /* Firefox */
  -moz-appearance: textfield; /* Firefox */
}

/* Remove spinner buttons in Chrome, Safari, and Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.auth-buttons {
  display: flex;
  gap: 1em;
  justify-content: center;
  /* align-items: center; */
}

.button-wrapper {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #ddd;
  height: 40;
}

.google-button .button-text {
  color: #4285f4;
  padding-left: 5px !important;
}

.apple-button .button-text {
  color: black;
}

/* Ensure the icon stays centered */
button.google-login-button,
.apple-logo {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 12px !important;
  padding: 0 8px 0 2px;
  font-weight: 600 !important;
  line-height: 1;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.google-login-button .abcRioButton {
  height: 50px !important; /* Adjust to your desired height */
  line-height: 50px !important; /* Aligns content vertically */
  display: flex;
  align-items: center;
}

.react-apple-signin-auth-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 8px 0 2px;
  font-size: 14px;
  font-size: 1.2em;
  line-height: 1;
  border: 1px solid #fff !important;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.apple-auth-btn {
  font-size: 16px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  size: 20px !important;
  border: 1px solid #dadce0 !important;
}

.notification-card {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  background-color: #3026f1;
  color: white;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-content {
  text-align: left;
}

.card-title_sender {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

.card-subtitle {
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
}

.payment-date {
  font-weight: bold;
}

.round {
  border-radius: 100px;
  background: #3026f1;
}

.ql-container {
  border: none !important;
}
.zoom-effect {
  animation: zoom-in 0.3s ease-in-out;
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.ql-editor {
  font-size: 16px; /* Set your desired font size */
  font-family: "Raleway-Variable";
}
.customers td,
.customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

.customers tr:hover {
  background-color: #ddd;
}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.mondial_scroll::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.mondial_scroll::-webkit-scrollbar-track {
  background: rgb(246, 246, 246); /* color of the tracking area */
}

.mondial_scroll::-webkit-scrollbar-thumb {
  background-color: blue; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

input::placeholder {
  opacity: 0.5;
  text-transform: none !important;
}

.hide_scroll {
  height: calc(100vh - 280px);
  overflow: hidden scroll;
}

.hide_scroll::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}

.hide_scroll::-webkit-scrollbar-track {
  background: #f9c7b8; /* color of the tracking area */
}

.hide_scroll::-webkit-scrollbar-thumb {
  background-color: blue; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #f9c7b8; /* creates padding around scroll thumb */
}

.mapboxgl-ctrl-bottom-right:first-child{
  display: none !important;
}

.typing {
  position: relative;
  margin-left: 5px;
  background: #FCE6DD;
  border-radius: 20px;
  padding: 10px;
}

.typing__dot {
  float: left;
  width: 4px;
  height: 4px;
  margin: 0 4px;
  background: #3026f1;
  border-radius: 50%;
  margin-top: -3px;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
